.slider-container {
    width: 100%;
    position: relative;
}

.size {
    margin-top: 70px;
}

.slide img {
    height: 40vh;
    width: 100%;
    object-fit: cover;
    position: relative;
}

.slick-prev, .slick-next {
    width: auto !important;
    height: 100px !important;
    top:40% !important;
    z-index: 1;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.custom-arrow2 img {
    background-color: #fff;
    padding: 16px 8px;
    border-radius: 2px;
    
}

.right{
    margin-right: 2rem;
    padding-bottom: 30px;

}
.left{
    margin-left: 2rem;
    padding-bottom: 30px;
}

.slick-prev {
    left: 0px;
}

.slick-next {
    right: 0px;
}

.icons {
    width: 30px;
    height: 30px;
}

/* Ensure dots are visible inside the image */
.slick-dots {
    display: block !important;
    position: absolute;
    bottom: 10px; /* Adjust this value to position inside the image */
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
}

.slick-dots li {
    display: inline-block;
    margin: 0 4px;
}

.slick-dots li button:before {
    font-size: 12px;
    color: white; /* Change color as needed */
}

.slick-dots li.slick-active button:before {
    color: white; /* Change color as needed */
    opacity: 1;
}


.leftRight{
    height: auto;
    width: 30px;
}

@media (max-width: 600px) {
    .slick-prev, .slick-next {
        width: auto;
        height: 50px;
    }
    
    .custom-arrow2 img {
        width: 20px;
        height: 20px;
    }
    .leftRight{
        height: auto;
        width: 20px;
    }
    .slick-next {
        right: -0px !important;
    }
    
}
