.wrappers {
  width: 100%;
  background: #fff;
  border-radius: 5px;
}
.wrappers header {
  color: #000000;
  font-size: 27px;
  font-weight: 600;
  text-align: center;
}
.wrappers form {
  height: 100%;
  display: flex;
  cursor: pointer;
  margin: 30px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  border: 2px dashed #3f00ff;
}
.wrappers form :where(i, p) {
  color: #3f00ff;
}
.wrappers form i {
  font-size: 50px;
}
.wrappers form p {
  margin-top: 15px;
  font-size: 16px;
}

.wrappers section .row {
  margin-bottom: 10px;
  background: #e9f0ff;
  list-style: none;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrappers section .row i {
  color: #3f00ff;
  font-size: 30px;
}
.wrappers section .details span {
  font-size: 14px;
}
