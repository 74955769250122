.Edittext{
    font-size: .75rem;
}
.boxBorderAddress{
    text-align: center;
    height: 100px;
    width: 100%;
    background-color: #bad495;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 30px;
}
.addressFont{
    font-size: 15px;
}
.borderNone{

    border: 1px solid #8cc33f;
    color: #8cc33f;
}
.brandColor{
    color: #8cc33f !important;
    border: 1px solid #dee2e6;
}
.brandColorBTN{
    background-color: #8cc33f !important;
    margin-top: 16px;
}
.brandColorOutline{
    border-color: #8cc33f !important;
    margin-top: 16px;
}
.dropText{
    color: #9ca3af;
}
/* check box */
