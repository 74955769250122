.sideBar{
    margin-top: 12rem;
}
.bgMenu{
    background-color: #f1f6ea60;
}
.topspace{
    padding-top: 20px;
}
.truncateWord{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
