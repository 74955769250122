.product-imgs {
  display: flex;
  list-style: none;
  align-items: center;
}

.UploadIcon {
  color: #8cc33f;
}

.active_button {
  background: #8cc33f;
  color: white;
}

.UploadBTN {
  border: 4px dotted #8cc33f;
  border-radius: 4px;
  padding: 4px 30px;
  color: #8cc33f;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
}

.img-select {
  list-style: none;
  margin-right: 1rem;
}

.img-display {
  position: relative;
  width: 100%;
}

.img-showcase {
  display: flex;
  width: 100%;
  transition: all 0.5s ease;
}

.flex {
  display: flex;
  align-items: flex-start;
}

.thumbnails {
  width: 100px;
}

.thumbnail img {
  width: 100%;
  height: 124px;
  border-radius: 4px;
  transition: transform 0.3s ease;
  object-fit: cover;
}

.thumbnail img:hover {
  transform: scale(1.1);
}

.magnified {
  position: relative;
  width: 400px;
  height: auto;
  margin-left: 6px;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.magnifier {
  position: absolute;
  border: 3px solid #ffffff;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  display: none;
  pointer-events: none;
  background-image: url("");
  transform: translate(-50%, -50%);
}
