.rating {
    --transition-duration: .75s;
    display: flex;
    font-size: 2rem;
    flex-direction: row-reverse;
    justify-content: flex-end;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rating__star {
    position: relative;
    padding: 5px;
    filter: grayscale(100%);
    opacity: .4;
    transition: all var(--transition-duration);
    cursor: pointer; /* Make it clear that these are clickable */
}

.rating__star:hover,
.rating__star:hover ~ .rating__star,
.rating__star.active, /* Ensure active stars have the same styles as hovered stars */
.rating__star.active ~ .rating__star {
    opacity: 1; /* Make sure hovered and active stars are fully opaque */
    filter: grayscale(0%); /* Remove grayscale on hover and active */
}

/* Active state for selected emoji */
.rating__star.active {
    transform: scale(1.2); /* Optional: scale the active emoji */
    transition: transform var(--transition-duration); /* Smooth transition for scaling */
}

@keyframes scale-effect {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
