@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700&display=swap");
*,
::before,
::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: #e5e7eb; /* 2 */
}
::before,
::after {
  --tw-content: "";
}

:root {
  --tw-bg-color: 97 172 68;
  --tw-secondary-color: 140 195 63;
}

html,
:host {
  line-height: 1.5; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -moz-tab-size: 4; /* 3 */
  -o-tab-size: 4;
  tab-size: 4; /* 3 */
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 4 */
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal; /* 5 */
  font-variation-settings: normal; /* 6 */
  -webkit-tap-highlight-color: transparent; /* 7 */
}
/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/
body {
  margin: 0; /* 1 */
  line-height: inherit; /* 2 */
}
/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/
hr {
  height: 0; /* 1 */
  color: inherit; /* 2 */
  border-top-width: 1px; /* 3 */
}
/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/
abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
/*
Remove the default font size and weight for headings.
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
/*
Reset links to optimize for opt-in styling instead of opt-out.
*/
a {
  color: inherit;
  text-decoration: inherit;
}
/*
Add the correct font weight in Edge and Safari.
*/
b,
strong {
  font-weight: bolder;
}
/*
1. Use the user's configured `mono` font-family by default.
2. Use the user's configured `mono` font-feature-settings by default.
3. Use the user's configured `mono` font-variation-settings by default.
4. Correct the odd `em` font sizing in all browsers.
*/
code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace; /* 1 */
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal; /* 2 */
  font-variation-settings: normal; /* 3 */
  font-size: 1em; /* 4 */
}
/*
Add the correct font size in all browsers.
*/
small {
  font-size: 80%;
}
/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/
table {
  text-indent: 0; /* 1 */
  border-color: inherit; /* 2 */
  border-collapse: collapse; /* 3 */
}
/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  -webkit-font-feature-settings: inherit;
  font-feature-settings: inherit; /* 1 */
  font-variation-settings: inherit; /* 1 */
  font-size: 100%; /* 1 */
  font-weight: inherit; /* 1 */
  line-height: inherit; /* 1 */
  letter-spacing: inherit; /* 1 */
  color: inherit; /* 1 */
  margin: 0; /* 2 */
  padding: 0; /* 3 */
}
/*
Remove the inheritance of text transform in Edge and Firefox.
*/
button,
select {
  text-transform: none;
}
/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/
button,
input:where([type="button"]),
input:where([type="reset"]),
input:where([type="submit"]) {
  -webkit-appearance: button; /* 1 */
  background-color: transparent; /* 2 */
  background-image: none; /* 2 */
}
/*
Use the modern Firefox focus style for all focusable elements.
*/
:-moz-focusring {
  outline: auto;
}
/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/
:-moz-ui-invalid {
  box-shadow: none;
}
/*
Add the correct vertical alignment in Chrome and Firefox.
*/
progress {
  vertical-align: baseline;
}
/*
Correct the cursor style of increment and decrement buttons in Safari.
*/
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/*
Remove the inner padding in Chrome and Safari on macOS.
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/*
Add the correct display in Chrome and Safari.
*/
summary {
  display: list-item;
}
/*
Removes the default spacing and border for appropriate elements.
*/
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}
fieldset {
  margin: 0;
  padding: 0;
}
legend {
  padding: 0;
}
ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
/*
Reset default styling for dialogs.
*/
dialog {
  padding: 0;
}
/*
Prevent resizing textareas horizontally by default.
*/
textarea {
  resize: vertical;
}
/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1; /* 1 */
  color: #9ca3af; /* 2 */
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1; /* 1 */
  color: #9ca3af; /* 2 */
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1; /* 1 */
  color: #9ca3af; /* 2 */
}
input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  opacity: 1; /* 1 */
  color: #9ca3af; /* 2 */
}
input::placeholder,
textarea::placeholder {
  opacity: 1; /* 1 */
  color: #9ca3af; /* 2 */
}
/*
Set the default cursor for buttons.
*/
button,
[role="button"] {
  cursor: pointer;
}
/*
Make sure disabled buttons don't get the pointer cursor.
*/
:disabled {
  cursor: default;
}
/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block; /* 1 */
  vertical-align: middle; /* 2 */
}
/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/
img,
video {
  max-width: 100%;
  height: auto;
}
/* Make elements with the HTML hidden attribute stay hidden by default */
[hidden] {
  display: none;
}
*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
::-ms-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 12px;
  padding-left: 12px;
}
@media (min-width: 540px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-right: 45px;
    padding-left: 45px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-right: 13rem;
    padding-left: 13rem;
  }
}
.form-input,
.form-textarea,
.form-select,
.form-multiselect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}
.form-input:focus,
.form-textarea:focus,
.form-select:focus,
.form-multiselect:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  border-color: #2563eb;
}
.form-input::-webkit-input-placeholder,
.form-textarea::-webkit-input-placeholder {
  color: #6b7280;
  opacity: 1;
}
.form-input::-moz-placeholder,
.form-textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}
.form-input:-ms-input-placeholder,
.form-textarea:-ms-input-placeholder {
  color: #6b7280;
  opacity: 1;
}
.form-input::-ms-input-placeholder,
.form-textarea::-ms-input-placeholder {
  color: #6b7280;
  opacity: 1;
}
.form-input::placeholder,
.form-textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}
.form-input::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}
.form-input::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}
.form-input::-webkit-datetime-edit {
  display: -webkit-inline-box;
  display: inline-flex;
}
.form-input::-webkit-datetime-edit,
.form-input::-webkit-datetime-edit-year-field,
.form-input::-webkit-datetime-edit-month-field,
.form-input::-webkit-datetime-edit-day-field,
.form-input::-webkit-datetime-edit-hour-field,
.form-input::-webkit-datetime-edit-minute-field,
.form-input::-webkit-datetime-edit-second-field,
.form-input::-webkit-datetime-edit-millisecond-field,
.form-input::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}
.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
.form-select:where([size]:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
  print-color-adjust: unset;
}
.form-checkbox,
.form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}
.form-checkbox {
  border-radius: 0px;
}
.form-radio {
  border-radius: 100%;
}
.form-checkbox:focus,
.form-radio:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
}
.form-checkbox:checked,
.form-radio:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.form-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}
@media (forced-colors: active) {
  .form-checkbox:checked {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
  }
}
.form-radio:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}
@media (forced-colors: active) {
  .form-radio:checked {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
  }
}
.form-checkbox:checked:hover,
.form-checkbox:checked:focus,
.form-radio:checked:hover,
.form-radio:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}
.form-checkbox:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
@media (forced-colors: active) {
  .form-checkbox:indeterminate {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
  }
}
.form-checkbox:indeterminate:hover,
.form-checkbox:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}
.pointer-events-none {
  pointer-events: none !important;
}
.collapse {
  visibility: collapse !important;
}
.fixed {
  position: fixed !important;
}
.absolute {
  position: absolute !important;
}
.relative {
  position: relative !important;
}
.sticky {
  position: sticky !important;
}
.inset-0 {
  inset: 0px !important;
}
.-bottom-20 {
  bottom: -5rem !important;
}
.-end-0 {
  inset-inline-end: -0px !important;
}
.-left-2 {
  left: -0.5rem !important;
}
.-left-3 {
  left: -0.75rem !important;
}
.-start-0 {
  inset-inline-start: -0px !important;
}
.-top-20 {
  top: -5rem !important;
}
.bottom-28 {
  bottom: 7rem !important;
}
.bottom-3 {
  bottom: 0.75rem !important;
}
.bottom-4 {
  bottom: 1rem !important;
}
.bottom-5 {
  bottom: 1.25rem !important;
}
.end-0 {
  inset-inline-end: 0px !important;
}
.end-3 {
  inset-inline-end: 0.75rem !important;
}
.end-4 {
  inset-inline-end: 1rem !important;
}
.end-5 {
  inset-inline-end: 1.25rem !important;
}
.end-\[3px\] {
  inset-inline-end: 3px !important;
}
.left-\[2px\] {
  left: 2px !important;
}
.start-0 {
  inset-inline-start: 0px !important;
}
.start-0\.5 {
  inset-inline-start: 0.125rem !important;
}
.start-3 {
  inset-inline-start: 0.75rem !important;
}
.start-4 {
  inset-inline-start: 1rem !important;
}
.top-0 {
  top: 0px !important;
}
.top-0\.5 {
  top: 0.125rem !important;
}
.top-1\/4 {
  top: 25% !important;
}
.top-2\/4 {
  top: 50% !important;
}
.top-20 {
  top: 5rem !important;
}
.top-3 {
  top: 0.75rem !important;
}
.top-\[10px\] {
  top: 10px !important;
}
.top-\[2px\] {
  top: 2px !important;
}
.top-\[40\%\] {
  top: 40% !important;
}
.top-\[9px\] {
  top: 9px !important;
}
.z-1 {
  z-index: 1 !important;
}
.z-10 {
  z-index: 10 !important;
}
.z-2 {
  z-index: 2 !important;
}
.z-50 {
  z-index: 50 !important;
}
.order-1 {
  -webkit-box-ordinal-group: 2 !important;
  -ms-flex-order: 1 !important;
  order: 1 !important;
}
.order-2 {
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
}
.order-3 {
  -webkit-box-ordinal-group: 4 !important;
  -ms-flex-order: 3 !important;
  order: 3 !important;
}
.col-span-12 {
  grid-column: span 12 / span 12 !important;
}
.col-span-6 {
  grid-column: span 6 / span 6 !important;
}
.m-0 {
  margin: 0px !important;
}
.m-0\.5 {
  margin: 0.125rem !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-6 {
  margin: 1.5rem !important;
}
.m-auto {
  margin: auto !important;
}
.m-px {
  margin: 1px !important;
}
.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.mx-0\.5 {
  margin-left: 0.125rem !important;
  margin-right: 0.125rem !important;
}
.mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}
.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.-mt-32 {
  margin-top: -8rem !important;
}
.-mt-\[140px\] {
  margin-top: -140px !important;
}
.-mt-\[30px\] {
  margin-top: -30px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 0.75rem !important;
}
.mb-4 {
  margin-bottom: 1rem !important;
}
.mb-5 {
  margin-bottom: 1.25rem !important;
}
.mb-6 {
  margin-bottom: 1.5rem !important;
}
.me-1 {
  -webkit-margin-end: 0.25rem !important;
  margin-inline-end: 0.25rem !important;
}
.me-1\.5 {
  -webkit-margin-end: 0.375rem !important;
  margin-inline-end: 0.375rem !important;
}
.me-2 {
  -webkit-margin-end: 0.5rem !important;
  margin-inline-end: 0.5rem !important;
}
.me-3 {
  -webkit-margin-end: 0.75rem !important;
  margin-inline-end: 0.75rem !important;
}
.me-4 {
  -webkit-margin-end: 1rem !important;
  margin-inline-end: 1rem !important;
}
.ms-2 {
  -webkit-margin-start: 0.5rem !important;
  margin-inline-start: 0.5rem !important;
}
.ms-3 {
  -webkit-margin-start: 0.75rem !important;
  margin-inline-start: 0.75rem !important;
}
.ms-4 {
  -webkit-margin-start: 1rem !important;
  margin-inline-start: 1rem !important;
}
.ms-5 {
  -webkit-margin-start: 1.25rem !important;
  margin-inline-start: 1.25rem !important;
}
.ms-auto {
  -webkit-margin-start: auto !important;
  margin-inline-start: auto !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-10 {
  margin-top: 2.5rem !important;
}
.mt-12 {
  margin-top: 3rem !important;
}
.mt-14 {
  margin-top: 3.5rem !important;
}
.mt-16 {
  margin-top: 4rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-20 {
  margin-top: 5rem !important;
}
.mt-24 {
  margin-top: 6rem !important;
}
.mt-3 {
  margin-top: 0.75rem !important;
}
.mt-4 {
  margin-top: 1rem !important;
}
.mt-5 {
  margin-top: 1.25rem !important;
}
.mt-6 {
  margin-top: 1.5rem !important;
}
.mt-7 {
  margin-top: 1.75rem !important;
}
.mt-8 {
  margin-top: 2rem !important;
}
.mt-\[10px\] {
  margin-top: 10px !important;
}
.mt-\[70px\] {
  margin-top: 70px !important;
}
.block {
  display: block !important;
}
.inline-block {
  display: inline-block !important;
}
.inline {
  display: inline !important;
}
.flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
.table {
  display: table !important;
}
.grid {
  display: grid !important;
}
.hidden {
  display: none !important;
}
.size-10 {
  width: 2.5rem !important;
  height: 2.5rem !important;
}
.size-20 {
  width: 5rem !important;
  height: 5rem !important;
}
.size-4 {
  width: 1rem !important;
  height: 1rem !important;
}
.size-5 {
  width: 1.25rem !important;
  height: 1.25rem !important;
}
.size-6 {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.size-7 {
  width: 1.75rem !important;
  height: 1.75rem !important;
}
.size-8 {
  width: 2rem !important;
  height: 2rem !important;
}
.size-9 {
  width: 2.25rem !important;
  height: 2.25rem !important;
}
.size-\[30px\] {
  width: 30px !important;
  height: 30px !important;
}
.size-\[40px\] {
  width: 40px !important;
  height: 40px !important;
}
.h-10 {
  height: 2.5rem !important;
}
.h-11 {
  height: 2.75rem !important;
}
.h-14 {
  height: 3.5rem !important;
}
.h-20 {
  height: 5rem !important;
}
.h-28 {
  height: 7rem !important;
}
.h-4 {
  height: 1rem !important;
}
.h-5 {
  height: 1.25rem !important;
}
.h-52 {
  height: 13rem !important;
}
.h-6 {
  height: 1.5rem !important;
}
.h-7 {
  height: 1.75rem !important;
}
.h-8 {
  height: 2rem !important;
}
.h-9 {
  height: 2.25rem !important;
}
.h-\[130px\] {
  height: 130px !important;
}
.h-\[18px\] {
  height: 18px !important;
}
.h-\[22px\] {
  height: 22px !important;
}
.h-\[35px\] {
  height: 35px !important;
}
.h-\[46px\] {
  height: 46px !important;
}
.h-\[500px\] {
  height: 500px !important;
}
.h-\[50px\] {
  height: 50px !important;
}
.h-\[80px\] {
  height: 80px !important;
}
.h-auto {
  height: auto !important;
}
.h-full {
  height: 100% !important;
}
.h-screen {
  height: 100vh !important;
}
.max-h-6 {
  max-height: 1.5rem !important;
}
.min-h-screen {
  min-height: 100vh !important;
}
.w-10 {
  width: 2.5rem !important;
}
.w-11 {
  width: 2.75rem !important;
}
.w-12 {
  width: 3rem !important;
}
.w-14 {
  width: 3.5rem !important;
}
.w-16 {
  width: 4rem !important;
}
.w-20 {
  width: 5rem !important;
}
.w-24 {
  width: 6rem !important;
}
.w-28 {
  width: 7rem !important;
}
.w-4 {
  width: 1rem !important;
}
.w-48 {
  width: 12rem !important;
}
.w-52 {
  width: 13rem !important;
}
.w-56 {
  width: 14rem !important;
}
.w-6 {
  width: 1.5rem !important;
}
.w-64 {
  width: 16rem !important;
}
.w-7 {
  width: 1.75rem !important;
}
.w-72 {
  width: 18rem !important;
}
.w-9 {
  width: 2.25rem !important;
}
.w-\[130px\] {
  width: 130px !important;
}
.w-\[18px\] {
  width: 18px !important;
}
.w-\[35px\] {
  width: 35px !important;
}
.w-\[80px\] {
  width: 80px !important;
}
.w-full {
  width: 100% !important;
}
.min-w-\[100px\] {
  min-width: 100px !important;
}
.min-w-\[220px\] {
  min-width: 220px !important;
}
.min-w-full {
  min-width: 100% !important;
}
.max-w-\[400px\] {
  max-width: 400px !important;
}
.max-w-md {
  max-width: 28rem !important;
}
.max-w-xl {
  max-width: 36rem !important;
}
.flex-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 0% !important;
  flex: 1 1 0% !important;
}
.shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
.-rotate-90 {
  --tw-rotate: -90deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.rotate-180 {
  --tw-rotate: 180deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.rotate-90 {
  --tw-rotate: 90deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.resize {
  resize: both !important;
}
.list-none {
  list-style-type: none !important;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}
.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}
.flex-col {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
.items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.justify-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.justify-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.gap-3 {
  gap: 0.75rem !important;
}
.gap-4 {
  gap: 1rem !important;
}
.gap-5 {
  gap: 1.25rem !important;
}
.gap-6 {
  gap: 1.5rem !important;
}
.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
}
.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
}
.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.125rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
}
.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}
.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-x-auto {
  overflow-x: auto !important;
}
.scroll-smooth {
  scroll-behavior: smooth !important;
}
.whitespace-nowrap {
  white-space: nowrap !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-full {
  border-radius: 9999px !important;
}
.rounded-lg {
  border-radius: 0.5rem !important;
}
.rounded-md {
  border-radius: 0.375rem !important;
}
.rounded-xl {
  border-radius: 0.75rem !important;
}
.rounded-b-full {
  border-bottom-right-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}
.rounded-b-md {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}
.rounded-e-3xl {
  border-start-end-radius: 1.5rem !important;
  border-end-end-radius: 1.5rem !important;
}
.rounded-s-3xl {
  border-start-start-radius: 1.5rem !important;
  border-end-start-radius: 1.5rem !important;
}
.rounded-t-full {
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
}
.rounded-ee-xl {
  border-end-end-radius: 0.75rem !important;
}
.rounded-ss-xl {
  border-start-start-radius: 0.75rem !important;
}
.border {
  border-width: 1px !important;
}
.border-0 {
  border-width: 0px !important;
}
.border-x-4 {
  border-left-width: 4px !important;
  border-right-width: 4px !important;
}
.border-b {
  border-bottom-width: 1px !important;
}
.border-t {
  border-top-width: 1px !important;
}
.border-solid {
  border-style: solid !important;
}
.border-gray-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}
.border-gray-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}
.border-gray-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
}
.border-orange-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  ) !important;
}
.border-slate-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(30 41 59 / var(--tw-border-opacity)) !important;
}
.border-slate-900\/50 {
  border-color: rgb(15 23 42 / 0.5) !important;
}
.bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(22 28 45 / var(--tw-bg-opacity)) !important;
}
.bg-black\/25 {
  background-color: rgb(22 28 45 / 0.25) !important;
}
.bg-blue-600\/5 {
  background-color: rgb(37 99 235 / 0.05) !important;
}
.bg-dark-footer {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(22 28 40 / var(--tw-bg-opacity)) !important;
}
.bg-emerald-500\/5 {
  background-color: rgb(16 185 129 / 0.05) !important;
}
.bg-emerald-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity)) !important;
}
.bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}
.bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity)) !important;
}
.bg-gray-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity)) !important;
}
.bg-gray-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}
.bg-gray-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity)) !important;
}
.bg-indigo-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity)) !important;
}
.bg-orange-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(
    var(--tw-secondary-color) / var(--tw-bg-opacity)
  ) !important;
}
.bg-orange-500\/10 {
  background-color: rgb(var(--tw-secondary-color) / 0.1) !important;
}
.bg-orange-500\/5 {
  background-color: rgb(var(--tw-secondary-color) / 0.05) !important;
}
.bg-orange-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(var(--tw-bg-color) / var(--tw-bg-opacity)) !important;
}
.bg-red-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity)) !important;
}
.bg-red-600\/5 {
  background-color: rgb(220 38 38 / 0.05) !important;
}
.bg-slate-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity)) !important;
}
.bg-slate-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;
}
.bg-slate-900\/30 {
  background-color: rgb(15 23 42 / 0.3) !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-violet-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(124 58 237 / var(--tw-bg-opacity)) !important;
}
.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.bg-white\/20 {
  background-color: rgb(255 255 255 / 0.2) !important;
}
.bg-white\/70 {
  background-color: rgb(255 255 255 / 0.7) !important;
}

.bg-gradient-to-b {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(var(--tw-gradient-stops))
  ) !important;
  background-image: linear-gradient(
    to bottom,
    var(--tw-gradient-stops)
  ) !important;
}
.bg-gradient-to-t {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(var(--tw-gradient-stops))
  ) !important;
  background-image: linear-gradient(
    to top,
    var(--tw-gradient-stops)
  ) !important;
}
.bg-none {
  background-image: none !important;
}
.from-slate-900\/60 {
  --tw-gradient-from: rgb(15 23 42 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-slate-900\/90 {
  --tw-gradient-from: rgb(15 23 42 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-transparent {
  --tw-gradient-from: transparent var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.via-black\/40 {
  --tw-gradient-to: rgb(22 28 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from),
    rgb(22 28 45 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}
.via-slate-900\/50 {
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from),
    rgb(15 23 42 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}
.via-slate-900\/80 {
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from),
    rgb(15 23 42 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}
.to-black {
  --tw-gradient-to: #161c2d var(--tw-gradient-to-position) !important;
}
.to-slate-900 {
  --tw-gradient-to: #0f172a var(--tw-gradient-to-position) !important;
}
.to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position) !important;
}
.bg-cover {
  background-size: cover !important;
}
.bg-center {
  background-position: center !important;
}
.bg-top {
  background-position: top !important;
}
.bg-no-repeat {
  background-repeat: no-repeat !important;
}
.object-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}
.p-0 {
  padding: 0px !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 0.75rem !important;
}
.p-4 {
  padding: 1rem !important;
}
.p-5 {
  padding: 1.25rem !important;
}
.p-6 {
  padding: 1.5rem !important;
}
.p-8 {
  padding: 2rem !important;
}
.p-px {
  padding: 1px !important;
}
.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.px-2\.5 {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}
.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.py-0\.5 {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-1\.5 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}
.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}
.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}
.py-24 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}
.py-28 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}
.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.py-32 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}
.py-36 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}
.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.py-\[30px\] {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.py-\[5px\] {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.py-\[60px\] {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pb-16 {
  padding-bottom: 4rem !important;
}
.pb-4 {
  padding-bottom: 1rem !important;
}
.pb-52 {
  padding-bottom: 13rem !important;
}
.pb-6 {
  padding-bottom: 1.5rem !important;
}
.pb-8 {
  padding-bottom: 2rem !important;
}
.pe-1 {
  -webkit-padding-end: 0.25rem !important;
  padding-inline-end: 0.25rem !important;
}
.pe-10 {
  -webkit-padding-end: 2.5rem !important;
  padding-inline-end: 2.5rem !important;
}
.pe-40 {
  -webkit-padding-end: 10rem !important;
  padding-inline-end: 10rem !important;
}
.ps-0 {
  -webkit-padding-start: 0px !important;
  padding-inline-start: 0px !important;
}
.ps-0\.5 {
  -webkit-padding-start: 0.125rem !important;
  padding-inline-start: 0.125rem !important;
}
.ps-11 {
  -webkit-padding-start: 2.75rem !important;
  padding-inline-start: 2.75rem !important;
}
.ps-12 {
  -webkit-padding-start: 3rem !important;
  padding-inline-start: 3rem !important;
}
.ps-4 {
  -webkit-padding-start: 1rem !important;
  padding-inline-start: 1rem !important;
}
.ps-6 {
  -webkit-padding-start: 1.5rem !important;
  padding-inline-start: 1.5rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-36 {
  padding-top: 9rem !important;
}
.pt-4 {
  padding-top: 1rem !important;
}
.pt-6 {
  padding-top: 1.5rem !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-start {
  text-align: start !important;
}
.text-end {
  text-align: end !important;
}
.align-middle {
  vertical-align: middle !important;
}
.text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}
.text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}
.text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}
.text-5xl {
  font-size: 3rem !important;
  line-height: 1 !important;
}
.text-6xl {
  font-size: 3.75rem !important;
  line-height: 1 !important;
}
.text-9xl {
  font-size: 8rem !important;
  line-height: 1 !important;
}
.text-\[10px\] {
  font-size: 10px !important;
}
.text-\[13px\] {
  font-size: 13px !important;
}
.text-\[22px\] {
  font-size: 22px !important;
}
.text-\[28px\] {
  font-size: 28px !important;
}
.text-\[40px\] {
  font-size: 40px !important;
}
.text-\[50px\] {
  font-size: 50px !important;
}
.text-\[56px\] {
  font-size: 56px !important;
}
.text-base {
  font-size: 16px !important;
  line-height: 28px !important;
}
.text-lg {
  font-size: 18px !important;
  line-height: 28px !important;
}
.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}
.text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}
.text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}
.font-bold {
  font-weight: 700 !important;
}
.font-extrabold {
  font-weight: 800 !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-semibold {
  font-weight: 600 !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.italic {
  font-style: italic !important;
}
.leading-\[0\] {
  line-height: 0 !important;
}
.leading-\[110px\] {
  line-height: 110px !important;
}
.leading-\[35px\] {
  line-height: 35px !important;
}
.leading-\[72px\] {
  line-height: 72px !important;
}
.leading-normal {
  line-height: 1.5 !important;
}
.tracking-\[0\.5px\] {
  letter-spacing: 0.5px !important;
}
.tracking-\[1px\] {
  letter-spacing: 1px !important;
}
.tracking-wide {
  letter-spacing: 0.025em !important;
}
.tracking-wider {
  letter-spacing: 0.05em !important;
}
.text-amber-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(251 191 36 / var(--tw-text-opacity)) !important;
}
.text-amber-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(245 158 11 / var(--tw-text-opacity)) !important;
}
.text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(22 28 45 / var(--tw-text-opacity)) !important;
}
.text-dark {
  --tw-text-opacity: 1 !important;
  color: rgb(60 72 88 / var(--tw-text-opacity)) !important;
}
.text-gray-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(243 244 246 / var(--tw-text-opacity)) !important;
}
.text-gray-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity)) !important;
}
.text-gray-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(209 213 219 / var(--tw-text-opacity)) !important;
}
.text-gray-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(17 24 39 / var(--tw-text-opacity)) !important;
}
.text-green-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(22 163 74 / var(--tw-text-opacity)) !important;
}
.text-orange-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(251 146 60 / var(--tw-text-opacity)) !important;
}
.text-orange-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
}
.text-red-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(220 38 38 / var(--tw-text-opacity)) !important;
}
.text-slate-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(203 213 225 / var(--tw-text-opacity)) !important;
}
.text-slate-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-text-opacity)) !important;
}
.text-slate-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(100 116 139 / var(--tw-text-opacity)) !important;
}
.text-slate-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(15 23 42 / var(--tw-text-opacity)) !important;
}
.text-slate-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(2 6 23 / var(--tw-text-opacity)) !important;
}
.text-transparent {
  color: transparent !important;
}
.text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.text-white\/50 {
  color: rgb(255 255 255 / 0.5) !important;
}
.text-white\/70 {
  color: rgb(255 255 255 / 0.7) !important;
}
.text-white\/80 {
  color: rgb(255 255 255 / 0.8) !important;
}
.text-yellow-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(234 179 8 / var(--tw-text-opacity)) !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-5 {
  opacity: 0.05 !important;
}
.opacity-70 {
  opacity: 0.7 !important;
}
.opacity-80 {
  opacity: 0.8 !important;
}
.shadow {
  --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15) !important;
  --tw-shadow-colored: 0 0 3px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-md {
  --tw-shadow: 0 5px 13px rgb(60 72 88 / 0.2) !important;
  --tw-shadow-colored: 0 5px 13px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-sm {
  --tw-shadow: 0 2px 4px 0 rgb(60 72 88 / 0.15) !important;
  --tw-shadow-colored: 0 2px 4px 0 var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-gray-100 {
  --tw-shadow-color: #f3f4f6 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}
.outline-none {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}
.outline {
  outline-style: solid !important;
}
.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
}
.ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
}
.ring-gray-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity)) !important;
}
.ring-slate-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(248 250 252 / var(--tw-ring-opacity)) !important;
}
.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.backdrop-opacity-30 {
  --tw-backdrop-opacity: opacity(0.3) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia) !important;
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia) !important;
}
.transition {
  -webkit-transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, -webkit-box-shadow,
    -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, -webkit-box-shadow,
    -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter !important;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-box-shadow, -webkit-transform, -webkit-filter,
    -webkit-backdrop-filter !important;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  -webkit-transition-duration: 150ms !important;
  transition-duration: 150ms !important;
}
.transition-all {
  -webkit-transition-property: all !important;
  transition-property: all !important;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  -webkit-transition-duration: 150ms !important;
  transition-duration: 150ms !important;
}
.duration-500 {
  -webkit-transition-duration: 500ms !important;
  transition-duration: 500ms !important;
}
.ease-in-out {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}
/* // _helper.scss */
/*********************************/
/*             Helper            */
/*===============================*/
body {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  line-height: 28px;
  --tw-text-opacity: 1;
  color: rgb(22 28 45 / var(--tw-text-opacity));
}
body:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

p {
  line-height: 1.625;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "DM Sans", sans-serif;
  line-height: 1.5;
}

::-moz-selection {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

::selection {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

/*********************************/
/*         Menu                  */
/*===============================*/
#topnav {
  position: fixed;
  inset-inline-start: 0px;
  inset-inline-end: 0px;
  top: 0px;
  z-index: 999;
  border-width: 0px;
  background-color: transparent;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
#topnav .logo {
  --tw-text-opacity: 1 !important;
  color: rgb(22 28 45 / var(--tw-text-opacity)) !important;
}
#topnav .logo:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
#topnav .logo:where([dir="ltr"], [dir="ltr"] *) {
  float: left !important;
}
#topnav .logo:where([dir="rtl"], [dir="rtl"] *) {
  float: right !important;
}
#topnav .logo .l-dark {
  display: none;
}
#topnav .logo .l-light {
  display: inline-block;
}
#topnav .logo:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
#topnav .has-submenu {
  position: relative;
}
#topnav .has-submenu.active a {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
#topnav .has-submenu.active a:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}
#topnav .has-submenu.active .submenu li.active > a {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
}
#topnav .has-submenu.active .submenu li.active > a:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
}
#topnav .has-submenu.active.active .menu-arrow {
  border-top-color: transparent !important;
  border-inline-end-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  ) !important;
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  ) !important;
  border-inline-start-color: transparent !important;
}
#topnav .has-submenu .submenu .submenu-arrow {
  position: absolute;
  inset-inline-end: 1.25rem;
  top: 15px;
  display: inline-block;
  border-radius: 2px;
  border-width: 3px;
  border-top-color: transparent;
  border-inline-end-color: rgb(22 28 45 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-bottom-color: rgb(22 28 45 / var(--tw-border-opacity));
  border-inline-start-color: transparent;
}
#topnav .has-submenu .submenu .submenu-arrow:is(.dark *) {
  border-inline-end-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity));
}
#topnav .has-submenu .submenu .submenu-arrow:where([dir="ltr"], [dir="ltr"] *) {
  --tw-rotate: -45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#topnav .has-submenu .submenu .submenu-arrow:where([dir="rtl"], [dir="rtl"] *) {
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#topnav .has-submenu .submenu .has-submenu:hover > .submenu-arrow,
#topnav .has-submenu .submenu .has-submenu.active > .submenu-arrow {
  border-top-color: transparent;
  border-inline-end-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  );
  --tw-border-opacity: 1;
  border-bottom-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  );
  border-inline-start-color: transparent;
}
#topnav
  .has-submenu
  .submenu
  .has-submenu
  .submenu
  .has-submenu:hover
  .submenu-arrow {
  border-top-color: transparent;
  border-inline-end-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  );
  --tw-border-opacity: 1;
  border-bottom-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  );
  border-inline-start-color: transparent;
}
#topnav
  .has-submenu
  .submenu
  .has-submenu
  .submenu
  .has-submenu:hover
  > .submenu-arrow {
  border-top-color: transparent;
  border-inline-end-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  );
  --tw-border-opacity: 1;
  border-bottom-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  );
  border-inline-start-color: transparent;
}
#topnav .navbar-toggle {
  position: relative;
  margin: 0px;
  cursor: pointer;
  border-width: 0px;
  padding: 0px;
}
#topnav .navbar-toggle .lines {
  position: relative;
  margin-top: 30px;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  -webkit-margin-end: 0px;
  margin-inline-end: 0px;
  margin-bottom: 26px;
  display: block;
  height: 18px;
  width: 25px;
}
#topnav .navbar-toggle span {
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  margin-bottom: 5px;
  display: block;
  height: 2px;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(22 28 45 / var(--tw-bg-opacity));
}
#topnav .navbar-toggle span:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
#topnav .navbar-toggle span:last-child {
  margin-bottom: 0px;
}
#topnav .navbar-toggle.open span {
  position: absolute;
}
#topnav .navbar-toggle.open span:first-child {
  top: 6px;
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#topnav .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}
#topnav .navbar-toggle.open span:last-child {
  top: 6px;
  width: 100%;
  --tw-rotate: -45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#topnav .navbar-toggle.open span:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--tw-secondary-color) / var(--tw-bg-opacity));
}
#topnav .navbar-toggle:hover,
#topnav .navbar-toggle:focus,
#topnav .navbar-toggle .navigation-menu > li > a:hover {
  background-color: transparent;
}
#topnav .buy-button {
  line-height: 74px;
}
#topnav .buy-button {
  float: right;
}
#topnav .buy-button:where([dir="rtl"], [dir="rtl"] *) {
  float: left;
}
#topnav .buy-button > li {
  line-height: initial;
}
#topnav .buy-button .login-btn-primary,
#topnav .buy-button .btn-icon-dark {
  display: none;
}
#topnav .buy-button .login-btn-light,
#topnav .buy-button .btn-icon-light {
  display: inline-block;
}
#topnav .buy-button .search-bar .menu-search form {
  position: relative;
}
#topnav .buy-button .search-bar .searchform::after {
  pointer-events: none !important;
  position: absolute !important;
  inset-inline-end: 14px !important;
  top: 14px !important;
  font-size: 1.25rem !important;
  content: var(--tw-content) !important;
  line-height: 1.25rem !important;
}
#topnav .buy-button .search-bar .searchform input[type="text"] {
  display: block !important;
  height: 2.75rem !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
  -webkit-padding-end: 42px !important;
  padding-inline-end: 42px !important;
  -webkit-padding-start: 0.75rem !important;
  padding-inline-start: 0.75rem !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}
#topnav .buy-button .search-bar .searchform input[type="submit"] {
  display: none;
}
#topnav .buy-button .search-bar .searchform:after {
  content: "󰍉";
  font-family: "Material Design Icons";
}
#topnav .buy-button .dropdown .dropdown-toggle::after,
#topnav .buy-menu-btn .dropdown .dropdown-toggle::after {
  content: var(--tw-content);
  display: none;
}
#topnav .navigation-menu {
  margin: 0px;
  list-style-type: none;
  padding: 0px;
}
#topnav .navigation-menu > li {
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;
  display: block;
}
#topnav .navigation-menu > li:where([dir="ltr"], [dir="ltr"] *) {
  float: left;
}
#topnav .navigation-menu > li:where([dir="rtl"], [dir="rtl"] *) {
  float: right;
}
#topnav .navigation-menu > li > a {
  display: block;
  background-color: transparent;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 1px;
  --tw-text-opacity: 1;
  color: rgb(22 28 45 / var(--tw-text-opacity));
}
#topnav .navigation-menu > li > a:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li > a:active {
  --tw-text-opacity: 1;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity));
}
#topnav .navigation-menu > li:hover > a,
#topnav .navigation-menu > li.active > a {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
}
#topnav .navigation-menu > li:hover > a:is(.dark *),
#topnav .navigation-menu > li.active > a:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
}
#topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
  white-space: nowrap !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.04em !important;
  --tw-text-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-text-opacity)) !important;
}
#topnav .navigation-menu > li .submenu li {
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
}
#topnav .navigation-menu .has-submenu .menu-arrow {
  position: absolute;
  inset-inline-end: 0px;
  top: 33px;
  display: inline-block;
  border-radius: 2px;
  border-width: 3px;
  border-top-color: transparent;
  border-inline-end-color: rgb(22 28 45 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-bottom-color: rgb(22 28 45 / var(--tw-border-opacity));
  border-inline-start-color: transparent;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}
#topnav .navigation-menu .has-submenu .menu-arrow:is(.dark *) {
  border-inline-end-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity));
}
#topnav
  .navigation-menu
  .has-submenu
  .menu-arrow:where([dir="ltr"], [dir="ltr"] *) {
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#topnav
  .navigation-menu
  .has-submenu
  .menu-arrow:where([dir="rtl"], [dir="rtl"] *) {
  --tw-rotate: -45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#topnav .menu-extras:where([dir="ltr"], [dir="ltr"] *) {
  float: right;
}
#topnav .menu-extras:where([dir="rtl"], [dir="rtl"] *) {
  float: left;
}
#topnav.scroll {
  border-style: none;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
  --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
#topnav.scroll:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}
#topnav.scroll .navigation-menu > li > a {
  --tw-text-opacity: 1;
  color: rgb(22 28 45 / var(--tw-text-opacity));
}
#topnav.scroll .navigation-menu > li > a:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
#topnav.scroll .navigation-menu > li > .menu-arrow {
  border-top-color: transparent;
  border-inline-end-color: rgb(22 28 45 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-bottom-color: rgb(22 28 45 / var(--tw-border-opacity));
  border-inline-start-color: transparent;
}
#topnav.scroll .navigation-menu > li > .menu-arrow:is(.dark *) {
  border-inline-end-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity));
}
#topnav.scroll .navigation-menu > li:hover > a,
#topnav.scroll .navigation-menu > li.active > a {
  --tw-text-opacity: 1;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity));
}
#topnav.scroll .navigation-menu > li:hover > .menu-arrow,
#topnav.scroll .navigation-menu > li.active > .menu-arrow {
  border-top-color: transparent !important;
  border-inline-end-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  ) !important;
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  ) !important;
  border-inline-start-color: transparent !important;
}
#topnav.defaultscroll.dark-menubar .logo {
  line-height: 70px;
}
#topnav.defaultscroll.scroll .logo {
  line-height: 62px;
}
#topnav.nav-sticky {
  background-color: rgb(255 255 255 / 0.8);
  --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
  --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}
#topnav.nav-sticky:is(.dark *) {
  background-color: rgb(15 23 42 / 0.8);
  --tw-shadow-color: #374151;
  --tw-shadow: var(--tw-shadow-colored);
}
#topnav.nav-sticky .navigation-menu.nav-light > li > a {
  --tw-text-opacity: 1;
  color: rgb(22 28 45 / var(--tw-text-opacity));
}
#topnav.nav-sticky .navigation-menu.nav-light > li > a:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
  border-top-color: transparent !important;
  border-inline-end-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  ) !important;
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  ) !important;
  border-inline-start-color: transparent !important;
}
#topnav.nav-sticky
  .navigation-menu.nav-light
  > li:hover
  > .menu-arrow:is(.dark *),
#topnav.nav-sticky
  .navigation-menu.nav-light
  > li.active
  > .menu-arrow:is(.dark *) {
  border-inline-end-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  ) !important;
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  ) !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a:is(.dark *),
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
}
#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
  border-top-color: transparent !important;
  border-inline-end-color: rgb(22 28 45 / var(--tw-border-opacity)) !important;
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(22 28 45 / var(--tw-border-opacity)) !important;
  border-inline-start-color: transparent !important;
}
#topnav.nav-sticky
  .navigation-menu.nav-light
  .has-submenu
  .menu-arrow:is(.dark *) {
  border-inline-end-color: rgb(
    255 255 255 / var(--tw-border-opacity)
  ) !important;
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}
#topnav.nav-sticky.tagline-height {
  top: 0px !important;
}
#topnav.nav-sticky .buy-button .login-btn-primary,
#topnav.nav-sticky .buy-button .btn-icon-dark {
  display: inline-block;
}
#topnav.nav-sticky .buy-button .login-btn-light,
#topnav.nav-sticky .buy-button .btn-icon-light {
  display: none;
}
#topnav.nav-sticky .buy-button .search-dropdown .dark-icon {
  display: inline-block;
}
#topnav.nav-sticky .buy-button .search-dropdown .white-icon {
  display: none;
}
#topnav.nav-sticky .logo .l-dark {
  display: inline-block;
}
#topnav.nav-sticky .logo .l-light {
  display: none;
}

.logo {
  -webkit-margin-end: 15px;
  margin-inline-end: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 24px;
  font-weight: 600;
  line-height: 68px;
  letter-spacing: 1px;
}

@media (min-width: 1025px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    width: 1116px !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 992px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    width: 936px !important;
  }
}
@media (min-width: 992px) {
  #topnav .navigation-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #topnav .navigation-menu.justify-end > li:last-child {
    -webkit-margin-end: 0px;
    margin-inline-end: 0px;
  }
  #topnav .navigation-menu.justify-end > li:last-child > .sub-menu-item {
    -webkit-padding-end: 0px !important;
    padding-inline-end: 0px !important;
  }
  #topnav .navigation-menu.justify-start > li:first-child {
    -webkit-margin-start: 0px;
    margin-inline-start: 0px;
  }
  #topnav .navigation-menu > li .submenu {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    visibility: hidden;
    position: absolute;
    top: 100%;
    inset-inline-start: 0px;
    z-index: 1000;
    margin-top: 10px;
    min-width: 180px;
    list-style-type: none;
    border-radius: 0.375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0px;
    padding-right: 0px;
    opacity: 0;
    --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
    --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  #topnav .navigation-menu > li .submenu:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
    --tw-shadow-color: #374151;
    --tw-shadow: var(--tw-shadow-colored);
  }
  #topnav .navigation-menu > li .submenu li {
    position: relative;
    -webkit-margin-start: 0px;
    margin-inline-start: 0px;
  }
  #topnav .navigation-menu > li .submenu li a {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    clear: both;
    display: block;
    white-space: nowrap;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.04em;
    --tw-text-opacity: 1;
    color: rgb(22 28 45 / var(--tw-text-opacity));
  }
  #topnav .navigation-menu > li .submenu li a:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  #topnav .navigation-menu > li .submenu li a:hover {
    --tw-text-opacity: 1 !important;
    color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
  }
  #topnav .navigation-menu > li .submenu li a:hover:is(.dark *) {
    --tw-text-opacity: 1 !important;
    color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
  }
  #topnav .navigation-menu > li .submenu li ul {
    margin: 0px;
    list-style-type: none;
    -webkit-padding-start: 0px;
    padding-inline-start: 0px;
  }
  #topnav .navigation-menu > li .submenu.megamenu {
    position: fixed;
    inset-inline-start: 50%;
    top: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
  }
  #topnav
    .navigation-menu
    > li
    .submenu.megamenu:where([dir="ltr"], [dir="ltr"] *) {
    --tw-translate-x: -50%;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  #topnav
    .navigation-menu
    > li
    .submenu.megamenu:where([dir="rtl"], [dir="rtl"] *) {
    --tw-translate-x: 50%;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  #topnav .navigation-menu > li .submenu.megamenu > li {
    width: 20%;
    overflow: hidden;
    vertical-align: top;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    inset-inline-start: 100%;
    inset-inline-end: 100%;
    top: 0px;
    -webkit-margin-start: 10px;
    margin-inline-start: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li .submenu > li .submenu {
    inset-inline-start: 101%;
    top: 0px;
    -webkit-margin-start: 10px;
    margin-inline-start: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li > a {
    min-height: 62px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  #topnav .navigation-menu > li:hover > .menu-arrow {
    border-top-color: transparent !important;
    border-inline-end-color: rgb(
      var(--tw-secondary-color) / var(--tw-border-opacity)
    ) !important;
    --tw-border-opacity: 1 !important;
    border-bottom-color: rgb(
      var(--tw-secondary-color) / var(--tw-border-opacity)
    ) !important;
    border-inline-start-color: transparent !important;
  }
  #topnav .navigation-menu > li:hover > a,
  #topnav .navigation-menu > li.active > a {
    --tw-text-opacity: 1 !important;
    color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
  }
  #topnav .navigation-menu > li:hover > a:is(.dark *),
  #topnav .navigation-menu > li.active > a:is(.dark *) {
    --tw-text-opacity: 1 !important;
    color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
  }
  #topnav .navigation-menu > li.last-elements .submenu {
    inset-inline-start: auto;
    inset-inline-end: 0px;
  }
  #topnav .navigation-menu > li.last-elements .submenu::before {
    inset-inline-start: auto;
    content: var(--tw-content);
    inset-inline-end: 10px;
  }
  #topnav
    .navigation-menu
    > li.last-elements
    .submenu
    > li.has-submenu
    .submenu {
    inset-inline-start: auto;
    inset-inline-end: 100%;
    -webkit-margin-start: 0px;
    margin-inline-start: 0px;
    -webkit-margin-end: 10px;
    margin-inline-end: 10px;
  }
  #topnav .navigation-menu.nav-light > li > a {
    color: rgb(255 255 255 / 0.5);
  }
  #topnav .navigation-menu.nav-light > li.active > a {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
    border-top-color: transparent !important;
    border-inline-end-color: rgb(
      255 255 255 / var(--tw-border-opacity)
    ) !important;
    --tw-border-opacity: 1 !important;
    border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
    border-inline-start-color: transparent !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > a {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  }
  #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
    border-top-color: transparent !important;
    border-inline-end-color: rgb(255 255 255 / 0.5) !important;
    border-bottom-color: rgb(255 255 255 / 0.5) !important;
    border-inline-start-color: transparent !important;
  }
  #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
    border-top-color: transparent !important;
    border-inline-end-color: rgb(
      255 255 255 / var(--tw-border-opacity)
    ) !important;
    --tw-border-opacity: 1 !important;
    border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
    border-inline-start-color: transparent !important;
  }
  #topnav .buy-button {
    -webkit-margin-start: 15px;
    margin-inline-start: 15px;
    -webkit-padding-start: 15px;
    padding-inline-start: 15px;
  }
  #topnav .buy-button .search-dropdown .dark-icon {
    display: none;
  }
  #topnav .buy-button .search-dropdown .white-icon {
    display: inline-block;
  }
  #topnav .navbar-toggle {
    display: none;
  }
  #topnav #navigation {
    display: block !important;
  }
  #topnav.scroll {
    top: 0px;
  }
  #topnav.scroll .navigation-menu > li > a {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  #topnav.scroll-active .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media (max-width: 991px) {
  #topnav {
    min-height: 74px;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
    --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  #topnav:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
    --tw-shadow-color: #374151;
    --tw-shadow: var(--tw-shadow-colored);
  }
  #topnav .logo .l-dark {
    display: inline-block !important;
  }
  #topnav .logo .l-light {
    display: none !important;
  }
  #topnav .container {
    width: auto;
  }
  #topnav #navigation {
    max-height: 400px;
    --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
    --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  #topnav .navigation-menu {
    float: none !important;
  }
  #topnav .navigation-menu > li {
    float: none !important;
  }
  #topnav .navigation-menu > li .submenu {
    margin: 0px;
    display: none;
    list-style-type: none;
    -webkit-padding-start: 1.25rem;
    padding-inline-start: 1.25rem;
  }
  #topnav .navigation-menu > li .submenu li a {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: relative !important;
    display: block !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    letter-spacing: 0.04em !important;
    --tw-text-opacity: 1 !important;
    color: rgb(22 28 45 / var(--tw-text-opacity)) !important;
  }
  #topnav .navigation-menu > li .submenu li a:is(.dark *) {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  }
  #topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    list-style-type: none;
    -webkit-padding-start: 0px;
    padding-inline-start: 0px;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    position: relative;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity));
  }
  #topnav .navigation-menu > li .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu .submenu {
    display: none;
    list-style-type: none;
  }
  #topnav .navigation-menu > li .submenu .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    --tw-text-opacity: 1;
    color: rgb(22 28 45 / var(--tw-text-opacity));
  }
  #topnav .navigation-menu > li > a::after {
    position: absolute;
    content: var(--tw-content);
    inset-inline-end: 15px;
  }
  #topnav .navigation-menu > li > a:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  #topnav .navigation-menu > li > a:hover,
  #topnav .navigation-menu > li .submenu li a:hover,
  #topnav .navigation-menu > li.has-submenu.open > a {
    --tw-text-opacity: 1;
    color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity));
  }
  #topnav .menu-extras .menu-item {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }
  #topnav .menu-extras .menu-item:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  #topnav .navbar-header:where([dir="ltr"], [dir="ltr"] *) {
    float: left;
  }
  #topnav .navbar-header:where([dir="rtl"], [dir="rtl"] *) {
    float: right;
  }
  #topnav .buy-button .login-btn-primary,
  #topnav .buy-button .btn-icon-dark {
    display: inline-block !important;
  }
  #topnav .buy-button .login-btn-light,
  #topnav .buy-button .btn-icon-light {
    display: none;
  }
  #topnav .buy-button .search-dropdown .dark-icon {
    display: inline-block;
  }
  #topnav .buy-button .search-dropdown .white-icon {
    display: none;
  }
  #topnav .has-submenu .submenu .submenu-arrow {
    position: absolute;
    inset-inline-end: 1.25rem;
    top: 0.75rem;
  }
  #topnav
    .has-submenu
    .submenu
    .submenu-arrow:where([dir="ltr"], [dir="ltr"] *) {
    --tw-rotate: 45deg;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  #topnav .has-submenu.active a {
    --tw-text-opacity: 1;
    color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity));
  }

  #navigation {
    position: absolute;
    top: 74px;
    inset-inline-start: 0px;
    display: none;
    height: auto;
    width: 100%;
    overflow: auto;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  #navigation:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  }
  #navigation.open {
    display: block;
    overflow-y: auto;
  }
}
@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    inset-inline-end: 0.5rem;
    top: 1rem;
  }
}
@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    visibility: visible;
    margin-top: 0px;
    opacity: 1;
  }
  #topnav
    .navigation-menu
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    > .submenu {
    visibility: visible;
    -webkit-margin-start: 0px;
    margin-inline-start: 0px;
    -webkit-margin-end: 0px;
    margin-inline-end: 0px;
    opacity: 1;
  }
  #topnav
    .navigation-menu
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    > .submenu
    > li:hover
    > .submenu {
    visibility: visible;
    -webkit-margin-start: 0px;
    margin-inline-start: 0px;
    -webkit-margin-end: 0px;
    margin-inline-end: 0px;
    opacity: 1;
  }

  .navbar-toggle {
    display: block;
  }
}
@media (max-width: 425px) {
  #topnav .buy-menu-btn {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    display: block !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  #topnav .buy-menu-btn .dropdown .dropdown-menu.show {
    -webkit-transform: translate3d(0px, -54px, 0px) !important;
    transform: translate3d(0px, -54px, 0px) !important;
  }
}
.tagline {
  position: absolute;
  z-index: 99;
  width: 100%;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@media screen and (max-width: 575px) {
  .tagline {
    display: none;
  }

  .tagline-height {
    top: 0px !important;
  }
}
@media (min-width: 576px) {
  .tagline-height {
    top: 47px !important;
  }
}
.sidebar-nav {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0px;
  padding-right: 0px;
}
.sidebar-nav > .navbar-item {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 15px;
  padding-right: 15px;
}
.sidebar-nav > .navbar-item .navbar-link {
  font-size: 15px;
  font-weight: 600;
}
.sidebar-nav > .navbar-item .navbar-link .navbar-icon {
  -webkit-margin-end: 6px;
  margin-inline-end: 6px;
  font-size: 18px;
  line-height: 28px;
}
.sidebar-nav > .navbar-item:hover .navbar-link,
.sidebar-nav > .navbar-item.active .navbar-link {
  --tw-text-opacity: 1;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity));
}
.sidebar-nav li.active a {
  --tw-text-opacity: 1;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity));
}
.sidebar-nav li.account-menu.active .navbar-link,
.sidebar-nav li.account-menu:hover .navbar-link {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
}
.sidebar-nav li.account-menu.active .navbar-link:is(.dark *),
.sidebar-nav li.account-menu:hover .navbar-link:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

/*********************************/
/*             Helper            */
/*===============================*/
.container {
  position: relative;
}

/* Preloader */
#preloader {
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  z-index: 99999;
  position: fixed;
  inset: 0px;
}
#preloader #status {
  position: absolute;
  inset-inline-start: 0px;
  inset-inline-end: 0px;
  top: 50%;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#preloader #status .spinner {
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  height: 2.5rem;
  width: 2.5rem;
}
#preloader #status .spinner .double-bounce1,
#preloader #status .spinner .double-bounce2 {
  position: absolute;
  top: 0px;
  inset-inline-start: 0px;
  height: 100%;
  width: 100%;
  border-radius: 9999px;
  background-color: rgb(16 185 129 / 0.6);
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
#preloader #status .spinner .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/* Switcher */
.label .ball {
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
  --tw-translate-x: 0px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.checkbox:checked + .label .ball {
  --tw-translate-x: 1.5rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

/*********************************/
/*         Tobii Lightbox        */
/*===============================*/
/* Tobii Lighbox */
.tobii > button.tobii__close svg,
.tobii > button.tobii__prev svg,
.tobii > button.tobii__next svg {
  height: 2.25rem;
  width: auto;
}

.tobii__counter {
  font-size: 16px;
  line-height: 28px;
}

.tobii-zoom {
  display: block;
}

.tobii-zoom__icon {
  display: none;
}

#grid {
  padding: 0px;
}

@media (max-width: 640px) {
  [data-type] iframe,
  [data-type] video {
    width: 500px;
    height: 300px;
  }
}
@media (max-width: 425px) {
  [data-type] iframe,
  [data-type] video {
    width: 360px;
    height: 260px;
  }
}
@media (max-width: 375px) {
  [data-type] iframe,
  [data-type] video {
    width: 100%;
    height: auto;
  }
}
.img-display {
  overflow: hidden;
}

/*********************************/
/*         Testimonial           */
/*===============================*/
.tns-nav {
  margin-top: 0.75rem;
  text-align: center;
}
.tns-nav button {
  margin: 0.25rem;
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  background-color: rgb(22 28 45 / 0.2);
  padding: 2px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.tns-nav button:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(15 23 42 / var(--tw-border-opacity));
  background-color: rgb(255 255 255 / 0.2);
}
.tns-nav button.tns-nav-active {
  --tw-border-opacity: 1;
  border-color: rgb(var(--tw-secondary-color) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(var(--tw-secondary-color) / var(--tw-bg-opacity));
}

/* Tns control */
.tns-controls button[data-controls="prev"],
.tns-controls button[data-controls="next"] {
  position: absolute;
  top: 50%;
  z-index: 10;
  height: 2rem;
  width: 2rem;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius: 9999px;
  border-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  font-size: 16px;
  line-height: 28px;
  --tw-text-opacity: 1;
  color: rgb(60 72 88 / var(--tw-text-opacity));
  --tw-shadow: 0 5px 13px rgb(60 72 88 / 0.2);
  --tw-shadow-colored: 0 5px 13px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.tns-controls button[data-controls="prev"]:is(.dark *),
.tns-controls button[data-controls="next"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow-color: #374151;
  --tw-shadow: var(--tw-shadow-colored);
}
.tns-controls button[data-controls="prev"]:hover,
.tns-controls button[data-controls="next"]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--tw-secondary-color) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.tns-controls button[data-controls="prev"] {
  inset-inline-start: 0px;
}
.tns-controls button[data-controls="next"] {
  inset-inline-end: 0px;
}

/* RTL */
html[dir="rtl"] .tns-inner,
html[dir="rtl"] .tns-nav,
html[dir="rtl"] .swiper-slider-hero {
  direction: ltr !important;
}
html[dir="rtl"] .tns-visually-hidden {
  left: 0 !important;
  visibility: hidden;
}

/*****************/
/*  Swiper Slider     */
/*****************/
.swiper-slider-hero .swiper-container .swiper-button-prev:before,
.swiper-slider-hero .swiper-container .swiper-button-next:before {
  font-family: "Material Design Icons";
}
.swiper-slider-hero .swiper-container .swiper-button-prev:before {
  content: "󰅁";
}
.swiper-slider-hero .swiper-container .swiper-button-next:before {
  content: "󰅂";
}
.swiper-slider-hero .swiper-pagination-bullet {
  background-color: transparent;
  font-weight: 600;
  color: rgb(15 23 42 / 0.5);
}
.swiper-slider-hero .swiper-pagination-bullet:is(.dark *) {
  color: rgb(255 255 255 / 0.5);
}
.swiper-slider-hero .swiper-pagination-bullet-active {
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}
.swiper-slider-hero .swiper-pagination-bullet-active:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.swiper-slider-hero .swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-slider-hero .swiper-pagination-custom,
.swiper-slider-hero .swiper-pagination-fraction {
  bottom: 45px;
}
.swiper-slider-hero
  .swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 13px;
  margin-right: 13px;
}

/*********************************/
/*         Countdown             */
/*===============================*/
#countdown .count-down .count-number {
  height: 130px;
  width: 130px;
  border-radius: 9999px;
  background-color: rgb(255 255 255 / 0.1);
  font-size: 40px;
  line-height: 110px;
  --tw-shadow: 0 5px 13px rgb(60 72 88 / 0.2);
  --tw-shadow-colored: 0 5px 13px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
#countdown .count-down .count-head {
  position: relative;
  display: block;
  --tw-translate-y: -25px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* //maintenance */
#maintenance .indicator {
  font-size: 18px;
  line-height: 28px;
}

/*********************************/
/*             Contact           */
/*===============================*/
/* Validation */
.error {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0px;
  margin-right: 0px;
  display: none;
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

#ajaxsuccess {
  clear: both;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0px;
  margin-right: 0px;
  display: none;
  width: 100%;
  font-size: 16px;
  line-height: 28px;
}

.error_message,
#success_page {
  margin-bottom: 1.25rem;
  border-radius: 0.375rem;
  padding: 0.625rem;
  text-align: center;
}

.error_message {
  background-color: rgb(220 38 38 / 0.05);
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.contact-loader {
  display: none;
}

#success_page {
  background-color: rgb(16 185 129 / 0.05);
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  .md\:container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 12px;
    padding-left: 12px;
  }
  @media (min-width: 540px) {
    .md\:container {
      max-width: 540px;
    }
  }
  @media (min-width: 640px) {
    .md\:container {
      max-width: 640px;
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
  @media (min-width: 768px) {
    .md\:container {
      max-width: 768px;
    }
  }
  @media (min-width: 992px) {
    .md\:container {
      max-width: 992px;
    }
  }
  @media (min-width: 1024px) {
    .md\:container {
      max-width: 1024px;
      padding-right: 45px;
      padding-left: 45px;
    }
  }
  @media (min-width: 1280px) {
    .md\:container {
      max-width: 1280px;
      padding-right: 5rem;
      padding-left: 5rem;
    }
  }
  @media (min-width: 1536px) {
    .md\:container {
      max-width: 1536px;
      padding-right: 13rem;
      padding-left: 13rem;
    }
  }
}

.placeholder\:text-gray-200::-webkit-input-placeholder {
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity)) !important;
}

.placeholder\:text-gray-200::-moz-placeholder {
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity)) !important;
}

.placeholder\:text-gray-200:-ms-input-placeholder {
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity)) !important;
}

.placeholder\:text-gray-200::-ms-input-placeholder {
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity)) !important;
}

.placeholder\:text-gray-200::placeholder {
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity)) !important;
}

.hover\:border-orange-500:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  ) !important;
}

.hover\:border-orange-600:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(var(--tw-bg-color) / var(--tw-border-opacity)) !important;
}

.hover\:border-slate-900:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-orange-500:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(
    var(--tw-secondary-color) / var(--tw-bg-opacity)
  ) !important;
}

.hover\:bg-orange-600:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(var(--tw-bg-color) / var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-slate-900:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}

.hover\:text-orange-500:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
}

.hover\:text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 25px -3px rgb(60 72 88 / 0.15) !important;
  --tw-shadow-colored: 0 10px 25px -3px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 5px 13px rgb(60 72 88 / 0.2) !important;
  --tw-shadow-colored: 0 5px 13px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:border-orange-300:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgb(253 186 116 / var(--tw-border-opacity)) !important;
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:ring-orange-200:focus {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(254 215 170 / var(--tw-ring-opacity)) !important;
}

.focus\:ring-opacity-50:focus {
  --tw-ring-opacity: 0.5 !important;
}

.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px !important;
}

.group:hover .group-hover\:bottom-3 {
  bottom: 0.75rem !important;
}

.group:hover .group-hover\:bottom-5 {
  bottom: 1.25rem !important;
}

.group:hover .group-hover\:scale-105 {
  --tw-scale-x: 1.05 !important;
  --tw-scale-y: 1.05 !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.group:hover .group-hover\:scale-110 {
  --tw-scale-x: 1.1 !important;
  --tw-scale-y: 1.1 !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.group:hover .group-hover\:text-orange-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1 !important;
}

.group:hover .group-hover\:shadow-lg {
  --tw-shadow: 0 10px 25px -3px rgb(60 72 88 / 0.15) !important;
  --tw-shadow-colored: 0 10px 25px -3px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.dark\:block:is(.dark *) {
  display: block !important;
}

.dark\:inline-block:is(.dark *) {
  display: inline-block !important;
}

.dark\:hidden:is(.dark *) {
  display: none !important;
}

.dark\:border-gray-600:is(.dark *) {
  --tw-border-opacity: 1 !important;
  border-color: rgb(75 85 99 / var(--tw-border-opacity)) !important;
}

.dark\:border-gray-700:is(.dark *) {
  --tw-border-opacity: 1 !important;
  border-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}

.dark\:border-gray-800:is(.dark *) {
  --tw-border-opacity: 1 !important;
  border-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
}

.dark\:border-slate-800:is(.dark *) {
  --tw-border-opacity: 1 !important;
  border-color: rgb(30 41 59 / var(--tw-border-opacity)) !important;
}

.dark\:bg-orange-500:is(.dark *) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(
    var(--tw-secondary-color) / var(--tw-bg-opacity)
  ) !important;
}

.dark\:bg-orange-500\/20:is(.dark *) {
  background-color: rgb(var(--tw-secondary-color) / 0.2) !important;
}

.dark\:bg-slate-800:is(.dark *) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
}

.dark\:bg-slate-900:is(.dark *) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;
}

.dark\:bg-slate-900\/70:is(.dark *) {
  background-color: rgb(15 23 42 / 0.7) !important;
}

.dark\:bg-white:is(.dark *) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.dark\:text-gray-100:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(243 244 246 / var(--tw-text-opacity)) !important;
}

.dark\:text-gray-200:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity)) !important;
}

.dark\:text-gray-400:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}

.dark\:text-gray-50:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(249 250 251 / var(--tw-text-opacity)) !important;
}

.dark\:text-slate-200:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(226 232 240 / var(--tw-text-opacity)) !important;
}

.dark\:text-slate-400:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-text-opacity)) !important;
}

.dark\:text-white:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.dark\:text-white\/70:is(.dark *) {
  color: rgb(255 255 255 / 0.7) !important;
}

.dark\:shadow:is(.dark *) {
  --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15) !important;
  --tw-shadow-colored: 0 0 3px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.dark\:shadow-gray-700:is(.dark *) {
  --tw-shadow-color: #374151 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.dark\:shadow-gray-800:is(.dark *) {
  --tw-shadow-color: #1f2937 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.dark\:shadow-slate-800:is(.dark *) {
  --tw-shadow-color: #1e293b !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.dark\:ring-slate-800:is(.dark *) {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(30 41 59 / var(--tw-ring-opacity)) !important;
}

.dark\:hover\:border-gray-100:hover:is(.dark *) {
  --tw-border-opacity: 1 !important;
  border-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}

.dark\:hover\:border-orange-500:hover:is(.dark *) {
  --tw-border-opacity: 1 !important;
  border-color: rgb(
    var(--tw-secondary-color) / var(--tw-border-opacity)
  ) !important;
}

.dark\:hover\:bg-orange-500:hover:is(.dark *) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(
    var(--tw-secondary-color) / var(--tw-bg-opacity)
  ) !important;
}

.dark\:hover\:bg-slate-100:hover:is(.dark *) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity)) !important;
}

.dark\:hover\:bg-slate-800:hover:is(.dark *) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
}

.dark\:hover\:text-orange-500:hover:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--tw-secondary-color) / var(--tw-text-opacity)) !important;
}

.dark\:hover\:text-slate-900:hover:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(15 23 42 / var(--tw-text-opacity)) !important;
}

.dark\:hover\:text-white:hover:is(.dark *) {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.dark\:hover\:shadow-gray-700:hover:is(.dark *) {
  --tw-shadow-color: #374151 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.group:hover .group-hover\:dark\:shadow-gray-800:is(.dark *) {
  --tw-shadow-color: #1f2937 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

@media (min-width: 640px) {
  .sm\:-mt-\[200px\] {
    margin-top: -200px !important;
  }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

@media (min-width: 768px) {
  .md\:-end-24 {
    inset-inline-end: -6rem !important;
  }
  .md\:-start-24 {
    inset-inline-start: -6rem !important;
  }
  .md\:order-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .md\:order-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .md\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }
  .md\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }
  .md\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }
  .md\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }
  .md\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }
  .md\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }
  .md\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }
  .md\:col-start-2 {
    grid-column-start: 2 !important;
  }
  .md\:-mt-48 {
    margin-top: -12rem !important;
  }
  .md\:mb-0 {
    margin-bottom: 0px !important;
  }
  .md\:me-2 {
    -webkit-margin-end: 0.5rem !important;
    margin-inline-end: 0.5rem !important;
  }
  .md\:ms-6 {
    -webkit-margin-start: 1.5rem !important;
    margin-inline-start: 1.5rem !important;
  }
  .md\:mt-0 {
    margin-top: 0px !important;
  }
  .md\:mt-24 {
    margin-top: 6rem !important;
  }
  .md\:mt-\[84px\] {
    margin-top: 84px !important;
  }
  .md\:block {
    display: block !important;
  }
  .md\:flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .md\:hidden {
    display: none !important;
  }
  .md\:h-\[34rem\] {
    height: 34rem !important;
  }
  .md\:h-\[44rem\] {
    height: 44rem !important;
  }
  .md\:h-screen {
    height: 100vh !important;
  }
  .md\:w-1\/2 {
    width: 50% !important;
  }
  .md\:w-1\/3 {
    width: 33.333333% !important;
  }
  .md\:w-2\/3 {
    width: 66.666667% !important;
  }
  .md\:w-3\/4 {
    width: 75% !important;
  }
  .md\:w-36 {
    width: 9rem !important;
  }
  .md\:w-48 {
    width: 12rem !important;
  }
  .md\:w-52 {
    width: 13rem !important;
  }
  .md\:w-56 {
    width: 14rem !important;
  }
  .md\:shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
  .md\:rounded-md {
    border-radius: 0.375rem !important;
  }
  .md\:bg-top {
    background-position: top !important;
  }
  .md\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .md\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .md\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .md\:pt-28 {
    padding-top: 7rem !important;
  }
  .md\:pt-32 {
    padding-top: 8rem !important;
  }
  .md\:text-start {
    text-align: start !important;
  }
  .md\:text-end {
    text-align: end !important;
  }
  .md\:text-3xl {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }
  .md\:text-5xl {
    font-size: 3rem !important;
    line-height: 1 !important;
  }
  .md\:text-6xl {
    font-size: 3.75rem !important;
    line-height: 1 !important;
  }
  .md\:text-7xl {
    font-size: 4.5rem !important;
    line-height: 1 !important;
  }
  .md\:text-\[26px\] {
    font-size: 26px !important;
  }
  .md\:leading-normal {
    line-height: 1.5 !important;
  }
}

@media (min-width: 1024px) {
  .lg\:order-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .lg\:order-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .lg\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }
  .lg\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }
  .lg\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }
  .lg\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }
  .lg\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }
  .lg\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }
  .lg\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }
  .lg\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }
  .lg\:col-start-3 {
    grid-column-start: 3 !important;
  }
  .lg\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .lg\:ms-5 {
    -webkit-margin-start: 1.25rem !important;
    margin-inline-start: 1.25rem !important;
  }
  .lg\:ms-6 {
    -webkit-margin-start: 1.5rem !important;
    margin-inline-start: 1.5rem !important;
  }
  .lg\:ms-8 {
    -webkit-margin-start: 2rem !important;
    margin-inline-start: 2rem !important;
  }
  .lg\:mt-24 {
    margin-top: 6rem !important;
  }
  .lg\:flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .lg\:h-\[614\.66px\] {
    height: 614.66px !important;
  }
  .lg\:h-full {
    height: 100% !important;
  }
  .lg\:w-1\/3 {
    width: 33.333333% !important;
  }
  .lg\:w-1\/4 {
    width: 25% !important;
  }
  .lg\:w-2\/5 {
    width: 40% !important;
  }
  .lg\:w-3\/4 {
    width: 75% !important;
  }
  .lg\:w-4\/5 {
    width: 80% !important;
  }
  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }
  .lg\:justify-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .lg\:gap-6 {
    gap: 1.5rem !important;
  }
  .lg\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .lg\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .lg\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .lg\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .lg\:py-44 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .lg\:pb-24 {
    padding-bottom: 6rem !important;
  }
  .lg\:text-\[72px\] {
    font-size: 72px !important;
  }
}

@media (min-width: 1280px) {
  .xl\:h-\[738\.66px\] {
    height: 738.66px !important;
  }
  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }
}

.ltr\:block:where([dir="ltr"], [dir="ltr"] *) {
  display: block !important;
}

.ltr\:hidden:where([dir="ltr"], [dir="ltr"] *) {
  display: none !important;
}

.ltr\:rotate-0:where([dir="ltr"], [dir="ltr"] *) {
  --tw-rotate: 0deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.rtl\:-mt-1:where([dir="rtl"], [dir="rtl"] *) {
  margin-top: -0.25rem !important;
}

.rtl\:block:where([dir="rtl"], [dir="rtl"] *) {
  display: block !important;
}

.rtl\:hidden:where([dir="rtl"], [dir="rtl"] *) {
  display: none !important;
}

.rtl\:rotate-180:where([dir="rtl"], [dir="rtl"] *) {
  --tw-rotate: 180deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
