/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.brandColor{
  background-color: #8cc33f !important;
}

.Color{
  color: #8cc33f !important;
}

.fieldRequiredError {
  content: " \*";
  color: #ff2e00;
  font-weight: 200;
  font-size: 8px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.LoginImg{
  max-height: 688px !important;
}

.orderPopup .nav-link {
 color: #000 !important;
}

.orderPopup .nav{
  --bs-nav-link-color: #000 !important;
 }

 .marginProfile{
  margin-top: 300px !important;
 }

 .btn:disabled{
  border: 0px solid #000 !important;
 }