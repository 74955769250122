.brandLogoImage {
    height: 70px !important;
}

.inputPlace {
    width: 100% !important;
    padding: 6.2px 20px;
    border-radius: 5px 0px 0px 5px;
    outline: none;
    background-color: #fff;
}
.search {
    width: 50%;
}
.onSearchResulthover {
    background: #e9f1de;
}

.cartBTN{
    position: relative;
}

.feather-shopping-cart{
    position: absolute;
}

.cartCountPosition{
   font-size: 8px;
   color: #fff;
   background-color: #8cc33f;
   top: 5px;
   margin-bottom: 8px;
   height: 12px;
   width: 12px;
   border-radius: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   right: 5px;
}

.searchBtn {
    padding: 9px 20px;
    background-color: #e9f1de;
    border-radius: 0px 5px 5px 0px;
    color: #8cc33f;
    height: fit-content;
}

.searchBtn p {
    font-size: 1rem;
    font-weight: 600;
}
.RecentSearch {
    width: 100%;
    background-color: #fff;
    min-height: 200px;
    border-radius: 5px;
    top: 42px;
    padding: 20px;
}
.RecentSearch p {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #575757;
}
.RecentSearch p img {
    margin-right: 10px;
}

.list img {
    margin-right: 8px;
}
.list span {
    font-size: 1rem;
    font-weight: 600;
    color: #575757;
}
